import React from 'react';
import Link from 'next/link';
import { useModalState } from './modal/hooks/useModal';
import SupportFormModal from './modal/SupportFormModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { initTranslationBrowser } from '../../translation/translation-browser';
import { useRouter } from 'next/router';
import { useTranslation } from '../../translation/translations';
import { languageLinks } from '../../constants/languages';

const UiFooter = () => {
  const { handleModal } = useModalState();
  const openContactSupport = () => {
    handleModal!(<SupportFormModal />);
  };
  const router = useRouter();

  initTranslationBrowser();

  const { t } = useTranslation();

  return (
    <div className="ui-footer">
      <div className="ui-footer-index-container">
        <div className="ui-footer-row">
          <div className="ui-footer-column-2">
            <div className="ui-footer-disclaimer-title">{t('footer-disclaimer')}</div>
            <div className="ui-footer-disclaimer-text">{t('footer-disclaimer-text')}</div>
          </div>
          <div className="ui-footer-menus">
            <div className="ui-footer-column ui-footer-column-menu">
              <div className="ui-footer-titles">{t('footer-menu')}</div>
              <div className="ui-footer-links">
                <Link href="/">
                  <a className="footer-link">{t('footer-home')}</a>
                </Link>
                <Link href={t('/portfolio')}>
                  <a className="footer-link">{t('portfolio-main')}</a>
                </Link>
                <Link href={t('/screener')}>
                  <a className="footer-link">{t('screener-main')}</a>
                </Link>
                <Link href="/green">
                  <a className="footer-link">{t('green-main')}</a>
                </Link>
                <Link href="/exchanges">
                  <a className="footer-link">{t('footer-exchanges')}</a>
                </Link>
                {/*{router.locale === 'en' && (
                  <Link href="/about">
                    <a className="footer-link">{t('footer-about')}</a>
                  </Link>
                )}*/}
                {router.locale === 'hu' && (
                  <>
                    <a className="footer-link" href="https://iocharts.io/blog">
                      {t('footer-blog')}
                    </a>
                    <Link className="footer-link" href="/magyar-reszvenyek">
                      Magyar részvények
                    </Link>
                    <Link className="footer-link" href="/bux-index">
                      Bux index
                    </Link>
                  </>
                )}
                <a className="footer-link" onClick={openContactSupport}>
                  {t('footer-support')}
                </a>
              </div>
            </div>
            <div className="ui-footer-column ui-footer-column-languages">
              <div className="ui-footer-titles">{t('footer-language')}</div>
              <div className="ui-footer-links ui-footer-links-languages">
                {languageLinks.map((l) => (
                  <a className="footer-link footer-link-language" key={l.label} href={`https://iocharts.io${l.path}`}>
                    {l.label}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="ui-footer-row-2">
          <div>© 2024 {t('footer-all-rights')}</div>
          <div className={'footer-socials'}>
            <a
              href="https://x.com/b2emo_charts"
              className="footer-link-social"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={['fab', 'twitter']} />
            </a>
            <a
              href="https://www.facebook.com/iOCharts"
              className="footer-link-social"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={['fab', 'facebook']} />
            </a>
            <a
              href="https://discord.gg/34G5EAA9ZY"
              className="footer-link-social"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={['fab', 'discord']} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UiFooter;
