import React from 'react';
import {Button} from '@material-ui/core';
import {useModalState} from './hooks/useModal';
import {useTranslation} from "../../../translation/translations";

import {initTranslationBrowser} from "../../../translation/translation-browser";

export default function SupportFormModal() {
  initTranslationBrowser();

  const {t} = useTranslation();

  const { handleModal } = useModalState();

  const close = () => {
    handleModal!();
  }

  return (
        <div className="io-modal">

        <div className="io-modal-title">
          <h5>{t("contact-title")}</h5>
        </div>
        <div className="io-modal-body">
          <div className="support-form-description">
            {t("contact-2")}
            <br/><br/>
            {t("contact-1")} <a href="mailto:support@iocharts.io">support@iocharts.io</a>
          </div>
          <Button variant="outlined" className="mr-1" color="primary" onClick={close}>
            {t("button-close")}
          </Button>
        </div>

        </div>
  );
}
